import React from "react"
import PropTypes from "prop-types"
// import posthog from "posthog-js"

// Components //
import GlobalStyle from "../components/style"
import MainWrapper from "../components/mainwrapper"
import Navigation from "../components/navigation"
import Seo from "../components/seo"

// posthog.init("phc_XcpeeZdlOw2t5dq7v3S2JtwikHghyQljvW2GrTontme", {
//   api_host: "https://us.i.posthog.com",
//   person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
// })

const Layout = (props) => {
  return (
    <>
      <Seo metadata={props.pageContext.metadata} />
      <GlobalStyle />
      <MainWrapper>
        <Navigation />
        {props.children}
      </MainWrapper>
    </>
  )
}

Layout.propTypes = {
  pageContext: PropTypes.object,
  children: PropTypes.array,
}

export default Layout
